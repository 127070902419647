
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./GérardZlotykamien.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import GérardZlotykamienPhoto1 from "../../../../res/Photos site/Gerard Zlotykamien/ZLOTYKAMIEN.jpeg"
import GérardZlotykamienPhoto2 from "../../../../res/Photos site/Gerard Zlotykamien/ZLOTYKAMIEN2.jpeg"
import GérardZlotykamienPhoto3 from "../../../../res/Photos site/Gerard Zlotykamien/Zlotykamien3.webp"
import GérardZlotykamienPhoto4 from "../../../../res/Photos site/Gerard Zlotykamien/5.jpg"
import GérardZlotykamienPhoto5 from "../../../../res/Photos site/Gerard Zlotykamien/6.jpg"
import GérardZlotykamienPhoto6 from "../../../../res/Photos site/Gerard Zlotykamien/7.jpg"
import GérardZlotykamienPhoto7 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Gerard Zlotykamien - Mobilisation - 1965.png"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/zlotykamien/portrait.jpg'
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "GérardZlotykamien",
  name: "GérardZlotykamien",
  description:"<p>Gérard Zlotykamien commence à peindre en 1955. Sa rencontre avec Yves Klein, qui lui apprend le judo et la rigueur de l’acte créatif, est déterminante dans sa carrière. Ses premières interventions dans la rue datent de 1963, où il peint à la poire de lavement puis à la bombe aérosol, des silhouettes fantomatiques dans l'immense chantier dit du « trou des Halles » à Paris. Ses dessins, qu'il appelle « éphémères » évoquent les ombres humaines qui se sont imprimées sur les murs après l'explosion d'Hiroshima. Cadre aux Galeries Lafayette le jour, peintre la nuit, très en marge du milieu de l'Art, Zlotykamien a peint ses éphémères dans de nombreuses villes du monde : Leipzig (Allemagne), Le Cap (Afrique du Sud). En 1980, il est poursuivi par la ville d'ULM en Allemagne pour avoir peint plus de 70 \"éphémères\" sur les murs de la ville. Il sera relaxé. Par contre en 1984, il se voit infliger une amende de six cents francs avec sursis pour avoir peint sur les murs de la Fondation nationale des arts graphiques et plastiques. En 2009, Gérard Zlotykamien participe à la rétrospective <i>Né dans la Rue</i> à la Fondation Cartier, et en 2019 à l'exposition Conquête urbaine au Musée des Beaux-Arts de Calais. Il est fait chevalier de l’ordre des Arts et des Lettres en 2015</p>",
  pdp: Pdp,
  alt_pdp: "Photo de profil de GérardZlotykamien.",
  photos: [
    { src: GérardZlotykamienPhoto3, name: "GérardZlotykamien" },
    { src: GérardZlotykamienPhoto4, name: "GérardZlotykamien" },
    { src: GérardZlotykamienPhoto6, name: "GérardZlotykamien" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    { src: GérardZlotykamienPhoto7, name: "Gérard Zlotykamien", artist:"Gérard Zlotykamien\n(né en 1940)", title:"Mobilisation, 1965", desc1: "Techniques mixtes sur toile", desc2 :"Mixed media on canvas", desc3: "120 x 90 cm", desc4:" 47,2 x 35,4 in.", desc5:"Signée et titrée au dos : \" MOBILISATION, Gérard Zlotykamien \"", desc6:"Signed and dated at the back : \" MOBILISATION, Gérard Zlotykamien \"" },
  ],
};


const GérardZlotykamien = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>GERARD ZLOTYKAMIEN <h2 style={{paddingLeft: "16px"}}>(Français)</h2></h1>
          <h3 className={NameWrapper} >Né en 1940</h3>
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default GérardZlotykamien;