// extracted by mini-css-extract-plugin
export var ArtistDescription = "GérardZlotykamien-module--ArtistDescription--CryCY";
export var ArtistInfos = "GérardZlotykamien-module--ArtistInfos--HPLP0";
export var ButtonWrapper = "GérardZlotykamien-module--ButtonWrapper --Ka0bK";
export var CardWrapper = "GérardZlotykamien-module--CardWrapper--GRQb1";
export var CarrouselWrapper2 = "GérardZlotykamien-module--CarrouselWrapper2--xvacf";
export var Citations = "GérardZlotykamien-module--Citations--lybe1";
export var DescriptionWrapper = "GérardZlotykamien-module--DescriptionWrapper--5x2Xl";
export var ImageWrapper = "GérardZlotykamien-module--ImageWrapper--FF1Pt";
export var LinkWrapper = "GérardZlotykamien-module--LinkWrapper--N9Y7g";
export var MobileProtrait = "GérardZlotykamien-module--MobileProtrait--KJ1pb";
export var More = "GérardZlotykamien-module--More--3Po88";
export var MoreButton = "GérardZlotykamien-module--MoreButton--jHzYJ";
export var NameWrapper = "GérardZlotykamien-module--NameWrapper--EQ-8K";
export var PdpWrapper = "GérardZlotykamien-module--PdpWrapper--AriK3";
export var PhotosWrapper = "GérardZlotykamien-module--PhotosWrapper--qV+M1";
export var ProfilWrapper = "GérardZlotykamien-module--ProfilWrapper--zS5EH";
export var TitleWrapper = "GérardZlotykamien-module--TitleWrapper--5NO4I";
export var Wrapper = "GérardZlotykamien-module--Wrapper--FNx+q";